(() => {
    const hide_banner = () => {
        $('.js-cookie-banner').hide();
        $('.js-cookie-banner-overlay').hide();
    }

    const show_foreign_content = () => {
        $('[data-foreign-content]').each(function () {
            if (Cookies.get('allow-cookies') === 'all') {
                const content = $(this).attr('data-foreign-content');
                $(this).replaceWith(content);
            }
            else {
                const content = $('body > .js-blocked-template').html();
                $(this).html(content);
            }
        });
    }

    show_foreign_content();

    if (Cookies.get('allow-cookies')) {
        hide_banner();
    }

    $('body').on('click', '.js-cookie-banner__agree-all', function (event) {
        Cookies.set('allow-cookies', 'all', { expires: 300});
        hide_banner();
        show_foreign_content();
        event.preventDefault();
    });

    $('.js-cookie__link--allow-necessary').click(function (event) {
        Cookies.set('allow-cookies', 'necessary', { expires: 300});
        hide_banner();
        event.preventDefault();
    });
})();